












































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
import {
  GetKeshiOptions,
  GetXiyizhenduanOptions,
  GetHisPatientList,
  GetHisPatientDetail,
} from "@/request/datacenter";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends mixins(listMixin) {
  private data: any[] = [];
  private sources: any = [];
  private keshis: any = [];
  private zhenduans: any = [];
  private ifShowPatient: any = false;
  private readData: any = {};
  private get nav() {
    // const d = this.$store.state.systemInfo.SYSTEM_NAV;
    // let nav: any = {};
    // for (const key in d) {
    //   if (Object.prototype.hasOwnProperty.call(d, key)) {
    //     const ele = d[key];
    //     if (window.location.href.indexOf(ele.activePath) !== -1) {
    //       nav = ele;
    //     }
    //   }
    // }
    const nav: any = [
      {
        label: "门诊",
        value: "门诊",
        isShow: true,
        path: "/main/datacenter/his/his-menzhen",
        activePath: "/main/datacenter/his/his-menzhen",
      },
      {
        label: "住院",
        value: "住院",
        isShow: true,
        path: "/main/datacenter/his/his-zhuyuan",
        activePath: "/main/datacenter/his/his-zhuyuan",
      },
      {
        label: "检验",
        value: "检验",
        isShow: true,
        path: "/main/datacenter/his/his-jianyan",
        activePath: "/main/datacenter/his/his-jianyan",
      },
      {
        label: "检查",
        value: "检查",
        isShow: true,
        path: "/main/datacenter/his/his-jiancha",
        activePath: "/main/datacenter/his/his-jiancha",
      },
      {
        label: "处方",
        value: "处方",
        isShow: true,
        path: "/main/datacenter/his/his-chufang",
        activePath: "/main/datacenter/his/his-chufang",
      },
    ];
    return nav;
  }
  private ifShow(type: any) {
    let index = -1;
    this.nav.forEach((ele: any, i: any) => {
      if (ele.value == type) {
        console.log(ele);
        index = i;
      }
    });
    if (index == -1) {
      return false;
    } else {
      if (this.nav[index].isShow) {
        return true;
      } else {
        return false;
      }
    }
  }
  private goDetail(item: any, type: any) {
    this.$store.commit("updatePatient", item);
    let routerData: any = {};
    this.nav.forEach((ele: any) => {
      if (ele.value == type) {
        routerData = ele;
      }
    });
    this.$router.push(routerData.path);
  }
  private goExport() {
    this.$router.push("/main/datacenter/sizhen-export");
  }
  private openRead(item: any) {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        patient_id: item.patient_id,
      },
    };
    GetHisPatientDetail(this, params, loading).then((res: any) => {
      loading.close();
      this.readData = res;
      this.ifShowPatient = true;
    });
  }
  getList() {
    console.log(this.filters);
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      search: this.filters.search,
      科室名称: this.filters["科室"],
      西医诊断: this.filters["西医诊断"],
      order_field: this.filters.sort_field,
      order_value: this.filters.sort_value,
      current_page: this.filters.current_page,
      page_num: this.filters.page_count,
    };
    localStorage.setItem("hisListFilter", JSON.stringify(this.filters));
    GetHisPatientList(this, params, loading).then((res: any) => {
      this.data = res.data;
      this.filters.total_count = res.total;
      if (this.data.length > 0) {
        this.ifShowEmpty = false;
      } else {
        this.ifShowEmpty = true;
      }
    });
  }
  private getKeshi() {
    const params: any = {
      params: {
        type: "all",
      },
    };
    GetKeshiOptions(this, params).then((res: any) => {
      this.keshis = res;
    });
  }
  private getXiyizhenduan() {
    GetXiyizhenduanOptions(this, {}).then((res: any) => {
      this.zhenduans = res;
    });
  }

  mounted() {
    if (localStorage.getItem("hisListFilter")) {
      this.filters = JSON.parse(localStorage.getItem("hisListFilter") as any);
    }
    this.getKeshi();
    this.getXiyizhenduan();
    this.getList();
  }
}
