import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 获取科室名称选项
 * https://app.apifox.com/project/3433176/apis/api-169784852
 * @param patient_id 非必填
 * @param type type为all时代表his首页的科室列表，可选（all, 门诊，住院， 检查， 检验）
 */
const GetKeshiOptions = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/his/department", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获取西医诊断选项
 * https://app.apifox.com/project/3433176/apis/api-169784852
 */
const GetXiyizhenduanOptions = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/his/diagnosis", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获取医生选项
 * https://app.apifox.com/project/3433176/apis/api-169784852
 * @param type 如门诊
 * @param 身份证号 可选
 */
const GetDoctorOptions = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/his/doctor", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获取his数据列表
 * https://app.apifox.com/project/3433176/apis/api-169784852
 * @param 科室名称
 * @param 西医诊断
 * @param search
 * @param page_num
 * @param current_page
 */
const GetHisPatientList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/his/patient/list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 获取his患者详情
 * https://app.apifox.com/project/3433176/apis/api-169784852
 * @param patient_id
 */
const GetHisPatientDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/his/patient/info", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 门诊列表
 * https://app.apifox.com/project/3433176/apis/api-169784852
 * @param patient_id
 * @param 科室名称
 * @param 就诊医生
 * @param 门诊号
 * @param search
 * @param page_num
 * @param current_page
 */
const GetMenzhenList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/his/out_patient", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 门诊详情
 * https://app.apifox.com/project/3433176/apis/api-169784852
 * @param id
 */
const GetMenzhenDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/his/out_patient", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 住院列表
 * https://app.apifox.com/project/3433176/apis/api-169784852
 * @param patient_id
 * @param 科室名称
 * @param 就诊医生
 * @param 住院号
 * @param search
 * @param page_num
 * @param current_page
 */
const GetZhuyuanList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/his/hospitalization", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};
/**
 * @description 住院详情
 * https://app.apifox.com/project/3433176/apis/api-169784852
 * @param id
 */
const GetZhuyuanDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/his/hospitalization", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch(() => {
            if (loading) {
                loading.close();
            }
            reject();
        });
    });
};

export {
    GetKeshiOptions, GetXiyizhenduanOptions, GetDoctorOptions,
    GetHisPatientList, GetHisPatientDetail, GetMenzhenList, GetMenzhenDetail,
    GetZhuyuanList,

}

